/* @import './vendor/bootstrap.min.css'; */

@font-face {
  font-family: "Oxygen";
  font-weight: bold;
  src: url("../fonts/Oxygen-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Mulish";
  /* font-weight: bold; */
  src: url("../fonts/Mulish-VariableFont_wght.ttf") format("truetype");
}

:root {
  /* --color-primary: #60626E;
   */
  --color-primary: #000;
  --separator: #ff0000;

  --light-text: white;
  --color-drawerbackground: #ededf0;
  --color-screenbackground: #f7f8fc;
  --border-radius-md: 99rem;
  --font: "Mulish";
  --font-heading: "Mulish", sans-serif;
  --primary: #51d870;

  --secondary: #50c6db;
  --tertiary: #1859bb;
  --quaternary: #2a2c7c;
  --primary-rgb: 36, 153, 227;
  --secondary-rgb: 48, 198, 220;
  --tertiary-rgb: 24, 89, 187;
  --quaternary-rgb: 42, 44, 124;
  --primary-darker: #1d7ab4;
  --secondary-darker: #409faf;
  --tertiary-darker: #124188;
  --quaternary-darker: #1a1a4d;
  --body: #4e4e4e;
  --alternate: #7c7c7c;
  --muted: #afafaf;
  --separator: #dddddd;
  --separator-light: #f1f1f1;
  --body-rgb: 59, 59, 59;
  --alternate-rgb: 124, 124, 124;
  --muted-rgb: 176, 176, 176;
  --separator-rgb: 221, 221, 221;
  --separator-light-rgb: 241, 241, 241;
  --background: #f9f9f9;
  --foreground: #ffffff;
  --background-rgb: 249, 249, 249;
  --foreground-rgb: 255, 255, 255;
  --background-theme: #eaf0f1;
  --background-light: #f8f8f8;
  --gradient-1: #1399d7;
  --gradient-2: #11a3e3;
  --gradient-3: #1fafed;
  --gradient-1-lighter: #22b8f9;
  --gradient-1-darker: #1082b8;
  --gradient-2-darker: #0f8dc4;
  --gradient-3-darker: #1c9cd3;
  --light-text: #fff;
  --dark-text: #343a40;
  --light-text-darker: #eeeeee;
  --dark-text-darker: #23272b;
  --light-text-rgb: 255, 255, 255;
  --dark-text-rgb: 52, 58, 64;
  --danger: #cf2637;
  --info: #279aac;
  --warning: #ebb71a;
  --success: #439b38;
  --light: #dadada;
  --dark: #4e4e4e;
  --danger-darker: #771a23;
  --info-darker: #19545d;
  --warning-darker: #aa830f;
  --success-darker: #285422;
  --light-darker: #c9c9c9;
  --dark-darker: #282828;
  --body-darker: #333333;
  --alternate-darker: #616161;
  --muted-darker: #888888;
  --separator-darker: #c0c0c0;
  --danger-rgb: 182, 40, 54;
  --info-rgb: 41, 138, 153;
  --warning-rgb: 235, 183, 26;
  --success-rgb: 65, 139, 56;
  --light-rgb: 218, 218, 218;
  --dark-rgb: 78, 78, 78;
  --menu-shadow: 0px 3px 10px rgba(0, 0, 0, 0.12);
  --menu-shadow-navcolor: 0px 2px 6px rgba(0, 0, 0, 0.05);
  --background-navcolor-light: #fff;
  --background-navcolor-dark: #253a52;
  --theme-image-filter: hue-rotate(0deg);
}

#App {
  /* display: flex; */
  font-family: "Mulish";
  color: var(--color-primary);
  height: 100vh;
  width: 100vw;
}

.drawer-logo-container {
  display: inline-block;
}

.sh-11 {
  height: 100% !important;
}

.drawer-logo {
  width: 120px;
  margin-top: 20px;
  margin-left: 20px;
}

.drawer-container {
  flex: 1 1 auto;
  flex-basis: 17%;
  z-index: 999;
  background-color: var(--color-drawerbackground);
  box-shadow: 10px 1px 5px 0px rgba(189, 189, 189, 0.05);
  -webkit-box-shadow: 10px 1px 5px 0px rgba(189, 189, 189, 0.05);
  -moz-box-shadow: 10px 1px 5px 0px rgba(189, 189, 189, 0.05);
}

.drawer-menu {
  margin-top: 50px;
}

.drawer-menu-item {
  display: flex;
  align-items: center;
}

.drawer-icon {
  width: 24px;
  margin-right: 10px;
}

.drawer-icon > path {
  fill: var(--color-primary);
}

h1 {
  font-family: Oxygen;
  font-weight: bold;
}

/* Page content container. Scales with the drawer */
.page-content-container {
  padding: 1rem;
  flex: 20 1 auto;
  flex-basis: 83%;
  height: 100vh;
  background-color: var(--color-screenbackground);
  overflow-y: scroll;
}

.flex {
  display: flex;
  flex-direction: column;
}

.flex-vertical-center {
  margin: auto;
}

.vertical-center {
  align-content: center;
}

flex-align-right {
  margin-right: auto;
}

.card-body {
  background-color: white;
  border-radius: 10px;
}

a {
  text-decoration: none; /* no underlining */
}

ul {
  list-style-type: none; /* no bullets */
}

li {
  margin-top: 20px;
  margin-bottom: 20px;
}

tr {
  line-height: 3;
}

table {
  margin-top: 2em;
  border-spacing: 0;
}

.row-no-margin-y {
  margin-bottom: 0px !important;
  color: blue;
}

.card-header {
  padding-top: 0.5rem !important;
  text-align: center;
  margin-bottom: 2rem;
  padding: 0;
}

.outline-center {
  text-align: center;
}

.error-list-row {
  display: flex;
  height: 4rem;
  overflow: hidden;
}

.error-list-limit-height {
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
}

.add-flex {
  display: flex;
}

.flex-100 {
  flex-basis: 100%;
}

.limit-row-height {
  max-height: 300px;
}

.button-width {
  width: 10rem;
}

.center-child-elements {
  justify-content: center;
}

.wrapper {
  display: flex;
  flex-flow: row wrap;
  min-height: 84vh;
}

@media screen and (min-width: 480px) {
  .question-title {
    font-size: calc(32px + 24 * (100vw - 480px) / 760);
    font-weight: 700;
    color: black;
  }
}

.question-title {
  line-height: 1.1;
  font-weight: 600;
  font-size: 32px;
  color: black;
}

@media screen and (min-width: 1240px) {
  .question-title {
    font-size: 32px;
  }
  .question-description {
    font-size: 24px;
  }
}

@media only screen and (min-width: 64em) {
  .question-title {
    padding: 4rem 4rem;
    width: 25%;
    position: relative;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;

    order: 1;
    flex: 2;
    background-color: #e2f0fc;
  }
}

/* We tell all items to be 100% width, via flex-basis */
/* .wrapper > * {
  flex: 1 100%;
} */

/* We rely on source order for mobile-first approach
 * in this case:
 * 1. header
 * 2. question title
 * 3. question choices
 * 5. footer
 */

.aside .question-title {
  width: 100%;
}

/* Medium screens */
@media all and (min-width: 400px) {
  /* We tell both sidebars to share a row */
  .aside {
    flex: 1 auto;
  }

  .header {
    /* flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 100%; */
    width: 100%;
    min-height: 8vh;
  }
}

/* Large screens */
@media all and (min-width: 600px) {
  .aside-choices {
    flex: 6 0px;
    order: 2;
    background-color: #ffffff;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 4rem;
  }

  .aside-title {
    order: 1;
    flex: 2;
    background-color: #e2f0fc;
  }
}

.header-logo {
  display: flex;
  justify-content: center;
}

.header-menu {
  display: flex;
  background-color: #f0f0f4;
  flex-direction: row;
  align-items: center;
}

.scroll-section



/* Custom radio buttons */

.custom-radio__check {
  position: absolute;
  top: 2.4rem;
  right: 2.4rem;
  height: 2.4rem;
  width: 2.4rem;
  background: transparent;
  border-radius: 50%;
  -webkit-transform: translate3d(40%, -40%, 0) scale(0);
  transform: translate3d(40%, -40%, 0) scale(0);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  border: 1px solid #3fa1ed;
  transition: -webkit-transform 150ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition: transform 150ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition: transform 150ms cubic-bezier(0.455, 0.03, 0.515, 0.955),
    -webkit-transform 150ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

/* .custom-radio__input:checked + .custom-radio__label .custom-radio__check, .custom-radio__input:checked + .custom-radio__label:hover .custom-radio__check {
  -webkit-transform: translate3d(1.5rem, -1.5rem, 0) scale(1);
  transform: translate3d(1.5rem, -1.5rem, 0) scale(1);
} */

.custom-radio__labeltext {
  font-size: 1.6rem;
  line-height: 1.25;
  min-height: 4rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  text-align: center;
  font-weight: 600;
  color: #000000;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom-radio__label {
  cursor: pointer;
}

.d-block {
  width: 100%;
}

/* .custom-radio__input:checked + .custom-radio__label, .custom-radio__input:checked + .custom-radio__label:hover {
  box-shadow: inset 0 0 0 1px #3FA1ED;
} */

@media only screen and (min-width: 48em) {
  .custom-radio__label {
    -webkit-flex-direction: column;
    flex-direction: column;
    padding: 2rem 3.6rem;
  }
}

.custom-radio__label {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  background-color: #f7f7f7;
  border-radius: 4px;
  height: 100%;
  flex: 1;
  transition: box-shadow 150ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

/* input {
  line-height: normal;
} */

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

.c-custom-radio {
  position: relative;
  /* flex: 1 1 160px; */
  /* flex-wrap: nowrap; */
  /* display: flex; */
  /* flex-flow: row wrap; */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15%, 1fr));
  /* row-gap: 2rem; */
  /* column-gap: 2rem; */
  /* width: 200px; */
}

/* .custom-radio__input {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
} */

@media only screen and (min-width: 64em) {
  .wizard-step__formitem {
    padding: 8rem 4rem 0;
  }
}

.wizard__step.is--active .wizard-step__formitem {
  max-height: 1000rem;
  transition: max-height 0ms ease-in-out 0ms;
}

.wizard-step__formitem {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  padding: 2.4rem 3rem 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0ms ease-in-out 300ms;
}

/* Customize the standard Wizard 
1. hide the icons in the progress bar
*/
.custom-radio__label {
  cursor: pointer;
}

.wizard-icon-container {
  display: none;
}

.wizard-icon-circle {
  display: none;
}

.wizard-progress-bar {
  width: 100%;
  height: 15px;

  /* color: blue; */
  /* background-color: #51D870; */
}

@media only screen and (min-width: 64em) {
  .wizard__footer {
    margin: auto;
  }
}

.wizard__footer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  padding: 2rem;
}

.progress-bar-colors {
  background-color: #51d870;
}

.c-button--primary {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
  vertical-align: top;
  margin: 0px 5px;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  /* min-height: 4.0rem; */
  border-radius: 99rem;
  font-weight: 600;
  /* background-color: #999999; */
  color: #000000;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  transition: 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition-property: all;
  transition-property: color, background-color, box-shadow;
  background-color: var(--primary) !important;
  color: #000000;
}

.c-button--primary:hover {
  background-color: var(--secondary-darker) !important;
}

.results-select-button:hover {
  background-color: var(--secondary-darker) !important;
}

@media only screen and (min-width: 48em) {
  .c-button--primary {
    min-height: 4rem;
  }
}

.btn {
  color: #000;
}

/* Header logo */
.logo-image {
  width: 60px;
  height: 60px;
  margin-left: 2rem;
}

.logo-image-login {
  width: 100px;
  height: 100px;
  margin-left: 1rem;
}

.mb-5 {
  margin-top: 2rem;
  margin-bottom: 2rem !important;
}

.header__toggle {
  background-color: #ffffff;
  height: 4.8rem;
  min-width: 1.6rem;
  border-radius: 99rem;
  padding: 0 1.6rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  transition: box-shadow 200ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.header__username {
  background-color: #ffffff;
  height: 4.8rem;
  min-width: 1.6rem;
  border-radius: 99rem;
  padding: 0 1.6rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  transition: box-shadow 200ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger-react {
  margin-left: auto;
  margin-right: 2rem;
  background-color: #fff;
  border-radius: 50%;
}

.btn-close-menu {
  font-size: 14px;
  font-weight: 600;
  background-color: white;
}

.btn-close-menu:hover {
  background-color: var(--secondary-darker) !important;
}

@media only screen and (min-width: 48em) {
  .custom-radio__icon {
    margin-bottom: 1rem;
  }
}

.custom-radio__icon {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  height: 3.5rem;
}

.header__logo-label {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  font-weight: 600;
  color: #000000;
}

@media only screen and (min-width: 48em) {
  .header__logo-label {
    position: relative;
    margin-left: 2.4rem;
    padding: 0 0 0 2.4rem;
  }
}

@media only screen and (min-width: 48em) {
  .header__logo-label::before {
    position: absolute;
    left: 0;
    -webkit-transform: translateY(-1.2rem);
    transform: translateY(-1.2rem);
    content: "";
    background: #d9d9d9;
    width: 1px;
    height: 4.8rem;
    display: inline-block;
  }
}

.hamburgermenu {
  margin-left: auto;
}

.hamburger-react {
  margin-left: auto;
}

.dropdown-menu-styles {
  border-radius: 10px;
}

.mb-1.dropdown.btn-group {
  margin-left: auto;
}

.dropdown-toggle {
  margin-left: auto;
}

.dropdown-toggle::after {
  display: none;
}

/* Landing page styles */

@media only screen and (min-width: 64em) {
  .hero-home__title {
    width: 50%;
  }
}

@media screen and (min-width: 1240px) {
  .hero-home__title {
    margin-bottom: 64px;
  }
}

@media screen and (min-width: 480px) {
  .hero-home__title {
    margin-bottom: calc(32px + 32 * (100vw - 480px) / 760);
  }
}

.hero-home__title {
  margin-bottom: 32px;
  color: #000;
}

@media screen and (min-width: 1240px) {
  h1 {
    font-size: 34px;
  }
}

@media only screen and (min-width: 64em) {
  .hero-home__card-container {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: repeat(3, 1fr);
  }
}

.hero-home__card-container {
  display: -ms-grid;
  display: grid;
  grid-gap: 16px;
}

.c-cta-card.cta-card--green {
  background: #51d870;
}

@media screen and (min-width: 1240px) {
  .c-cta-card {
    border-radius: 16px;
  }
}

@media screen and (min-width: 480px) {
  .c-cta-card {
    border-radius: calc(12px + 4 * (100vw - 480px) / 760);
  }
}

@media screen and (min-width: 1240px) {
  .c-cta-card {
    padding: 40px;
  }
}

@media screen and (min-width: 480px) {
  .c-cta-card {
    padding: calc(24px + 16 * (100vw - 480px) / 760);
  }
}

/* Order the cards below each other on the screen when on mobile 
 */

@media screen and (max-width: 480px) {
  .c-cta-card .large-card {
    /* padding: calc(24px + 16 * (100vw - 480px) / 760);
     */
    background: yellow !important;
  }
}

.c-cta-card {
  background: #e2f0fc;
  padding: 24px;
  border-radius: 12px;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-flex-direction: column;
  flex-direction: column;
  margin-right: 1vw;
}

@media screen and (min-width: 1240px) {
  .cta-card__title {
    margin-bottom: 24px;
  }
}

@media screen and (min-width: 480px) {
  .cta-card__title {
    margin-bottom: calc(16px + 8 * (100vw - 480px) / 760);
  }
}

@media screen and (min-width: 1240px) {
  .cta-card__title {
    font-size: 32px;
  }
}

@media screen and (min-width: 480px) {
  .cta-card__title {
    font-size: calc(24px + 8 * (100vw - 480px) / 760);
  }
}

.cta-card__title {
  line-height: 1.125;
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 16px;
}

@media only screen and (min-width: 48em) {
  .c-button--secondary {
    min-height: 3rem;
  }
}

.c-button--secondary {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
  vertical-align: top;
  margin: 0;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 10px;
  margin-top: 10px;
  min-height: 3rem;
  border-radius: 99rem;
  font-weight: 600;
  background-color: #999999;
  color: #000000;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  transition: 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition-property: all;
  transition-property: color, background-color, box-shadow;
  background-color: #ffffff;
  box-shadow: 0 0 0 1px #ffffff inset;
  color: #000000;
}

a {
  text-decoration: none;
  color: currentColor;
  cursor: pointer;
  text-overflow: ellipsis;
  transition: 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition-property: border, color, background-color;
}

.recommendation-description-table {
  border-collapse: collapse;
  border: 1px solid;
}

.recommendation-description-table-element {
  border: 1px solid;
  text-align: left;
  vertical-align: top;
  padding: 0px 10px;
}

.cta-card__actions {
  margin-top: auto;
}

/* .c-cta-card {
  min-height: 250px;
} */

.button__label {
  margin-left: 10px;
  margin-right: 10px;
}

.button__label__survey {
  margin-left: 10px;
  margin-right: 10px;
}

.button__icon__survey {
  fill: #000000;
}

.hero-home__box.hero-home__box-4 {
  /* min-height: 250px; */
  background: url("../img/seaport.jpg");

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  border-radius: 16px;
}

/* Login Screen */

.imagebox {
  min-height: 100vh;
  background: url("../img/seaport.jpg");

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  /* border-radius: 16px; */
}

.forgot-popup {
  width: 50%;
  margin: 0% 0% 0% 37%;
}

.card-container {
  display: flex;
  min-height: 540px;
  height: 530px;
}

.parent-card-column {
  display: flex;
  width: 100%;
}

.card {
  border: 1px solid #ddd;
  padding: 20px;
  margin-right: 10px;

  text-align: center;
}

.large-card-title {
  margin-top: -60px;
  /* min-height: 40px; */
  margin-bottom: 35px;
  /* flex: 8; */
}

.large-card {
  flex-basis: 50%; /* Takes up 50% of the container's width */
  height: 100%; /* Full height */
  /* flex-direction: row; */
}

.card-column {
  display: flex;
  flex-direction: column;
  flex-basis: 50%; /* Takes up the remaining 50% of the container's width */
}

.small-card {
  flex: 1; /* Each small card takes equal space in the column */
  height: 48%;
}

.top-row-card {
  margin-bottom: 4%; /* Extra margin for the top row of cards = gutter */
}

.card-column {
  flex: 1; /* Each small card takes equal space in the column */
  height: 100%;
}

.survey-toggle-group {
  display: flex;
  justify-content: center;
  align-items: stretch;
  width: 95%; /* Ensure full width */
  margin-left: 2%;
}

.opinion-toggle-group .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 2vw; /* Responsive font size */
}

.survey-button {
  white-space: normal;
  font-size: 15px;
  width: 18%;
  min-height: 80px;
  border-radius: 10px;
  align-items: center;
  display: grid;
  /* padding-left: 0px; */
  /* text-align: center; */
}

.survey-button:hover {
  background-color: var(--secondary-darker) !important;
}

.horizontal-line {
  width: 40%;
  height: 1px;
  background-color: #000; /* or any color you prefer */
  margin: 20px auto; /* centers the line horizontally and adds vertical spacing */
}

.circle-image-wrapper-wrapper {
  display: flex;
  justify-content: center;
}

.circle-wrapper {
  border-radius: 50%;
  background-color: white;
  padding: 15px;
}

.circle-wrapper-results {
  border-radius: 50%;
  background-color: white;
  margin: 10px;
  padding: 15px;
}

.circle-wrapper img {
  display: block;
  /* border-radius: 50%; */
}

.col-auto {
  padding: 5px 0px;
}

@media screen and (max-width: 1249px) {
  h1 {
    font-size: 28px;
    margin: 10px 5px;
    text-align: center;
  }

  .area-select-dropdown {
    font-size: clamp(16px, 3vw, 18px) !important;
  }

  .question-title {
    margin: 0;
    background-color: #e2f0fc;
    padding: 1rem 1rem;
    width: 23%;
  }

  .question-description {
    font-size: clamp(12px, 5vw, 20px);
    padding: 10px 5px;
  }

  .card-container {
    flex-direction: column; /* Stelt de flex-richting in op verticaal */
    height: auto; /* Hoogte wordt automatisch bepaald door de inhoud */
  }

  .parent-card-column {
    flex-direction: row;
  }

  .large-card,
  .card-column {
    flex-basis: 100%; /* Elke kaart neemt de volledige breedte in */
    margin-bottom: 20px; /* Voeg wat ruimte toe tussen de kaarten */
  }

  .header__logo {
    max-width: 50% !important;
    padding: 0%;
    margin: 0%;
  }

  .header__label {
    max-width: 50% !important;
    padding: 0%;
    margin: 0%;
  }

  img {
    width: 100%;
    height: auto;
  }

  h3 {
    font-size: clamp(11px, 4.6vw, 18px);
  }

  .col-auto {
    padding: 5px 0px;
  }

  .hamburger-react {
    width: 40px;
  }

  .small-card {
    height: auto; /* Hoogte wordt automatisch bepaald door de inhoud */
  }

  .top-row-card {
    margin-bottom: 20px; /* Standaard ondermarge voor kleine kaarten */
  }

  /* Make the questions fill whole row on mobile */
  .aside-choices {
    width: 100%;
    padding: 3%;
  }

  .custom-radio__labeltext {
    font-size: 16px;
  }

  .survey-button {
    white-space: normal;
    font-size: small;
    width: 20%;
  }

  .results-select-button {
    width: auto;
  }

  .results-select-button.btn {
    font-size: 1.5vw;
    max-height: 30px;
  }

  .c-button--primary {
    font-size: 1.5vw;
    max-height: 30px;
  }

  .survey-toggle-group .btn {
    font-size: 1.5vw; /* Adjust font size for smaller devices */
  }

  .view-selection-toggle {
    width: 100%;
    margin: auto;
  }

  .ms-3 {
    display: none;
  }

  .forgot-popup {
    width: 50%;
    margin: 0% 0% 0% 31%;
  }
}

/* Aanpassing voor mobiele weergave */
@media screen and (max-width: 576px) {
  h1 {
    font-size: 28px;
    margin: 10px 5px;
    text-align: center;
  }

  .question-title {
    margin: auto;
    width: 100%;
    padding: 1rem;
  }

  .question-description {
    font-size: clamp(12px, 5vw, 20px);
    padding: 10px 5px;
  }

  .card-container {
    flex-direction: column; /* Stelt de flex-richting in op verticaal */
    height: auto; /* Hoogte wordt automatisch bepaald door de inhoud */
  }

  .parent-card-column {
    flex-direction: column;
  }

  .large-card,
  .card-column {
    flex-basis: 100%; /* Elke kaart neemt de volledige breedte in */
    margin-bottom: 20px; /* Voeg wat ruimte toe tussen de kaarten */
  }

  .header__logo {
    max-width: 50% !important;
    padding: 0%;
    margin: 0%;
  }

  .header__label {
    max-width: 50% !important;
    padding: 0%;
    margin: 0%;
  }

  img {
    width: 100%;
    height: auto;
  }

  h3 {
    font-size: clamp(11px, 4.6vw, 18px);
  }

  .col-auto {
    padding: 5px 0px;
  }

  .hamburger-react {
    width: 40px;
  }

  .wrapper {
    padding: 4%;
  }

  .small-card {
    height: auto; /* Hoogte wordt automatisch bepaald door de inhoud */
  }

  .top-row-card {
    margin-bottom: 20px; /* Standaard ondermarge voor kleine kaarten */
  }

  /* Make the questions fill whole row on mobile */
  .aside-choices {
    width: 100%;
  }
  .custom-radio__labeltext {
    font-size: 16px;
  }

  .survey-button {
    white-space: normal;
    font-size: small;
    width: 20%;
  }

  .results-select-button {
    width: auto;
  }

  .results-select-button.btn {
    font-size: 3vw;
  }

  .c-button--primary {
    font-size: 3vw;
    max-height: 40px;
  }

  .survey-toggle-group .btn {
    font-size: 3vw; /* Adjust font size for smaller devices */
  }

  .view-selection-toggle {
    width: 100%;
    margin: auto;
    text-align: center;
    margin-top: 1rem;
  }

  .ms-3 {
    display: none;
  }

  .forgot-popup {
    width: 50%;
    margin: 0% 0% 0% 31%;
  }
}

.landing-page-image {
  width: 70px;
  padding: 0px;
}

.tier-status-card {
  height: 60px;

  width: 100%;
}

/* Tiers overview in Survey*/
.tiers-image-small {
  width: 30px;
  margin-top: -8px;
}

/* .tiers-status-card-horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
} */

.tiers-status-column {
  margin-left: 0;
  margin-right: 0;
}
.tiers-status-text {
  font-size: 16px;
}

.uppercase {
  text-transform: uppercase;
}

.capitalized {
  text-transform: capitalize;
}

.text-small-upper {
  font-size: small;
  text-transform: uppercase;
  color: darkgray;
}

.small-icon-button {
  font-size: small;
  min-height: 0;
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
}

.tier-select-dropdown {
  margin-top: 2vh;
  background-color: white;
  border-radius: 8px;
  width: 100%;
  font-size: clamp(11px, 4.5vw, 16px);
}

.area-select-dropdown {
  background-color: white;
  border-radius: 8px;
  width: 100%;
  font-size: clamp(16px, 3vw, 24px);
  font-weight: 700;
}

.area-select-dropdown.dropdown-toggle.btn.btn-primary {
  background-color: white;
}

.dropdown-menu {
  border-radius: 8px;
  width: 100%;
}

.tier-select-dropdown.dropdown-toggle.btn.btn-primary {
  background-color: white;
}

.recommendations-container {
  display: flex; /* Make the bottom row a flex container */
  flex: 2;
  flex-direction: row;
  width: 100%;
}

.recommendations-card {
  display: flex;
  flex-direction: column;
  flex: 1; /* Each small card takes equal space in the column */
}

.recommendation-circle {
  flex: 2;
}

.recommendation-title {
  flex: 1;
  margin: auto;
}

.accordion-header {
  font-size: clamp(12px, 3.5vw, 15px);
}

.accordion-button:after {
  content: "\2303";
  font-weight: bold;
  font-size: 16px;
  color: #777;
  float: right;
  margin-left: 8px;
}

.pill {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 10px;
  color: white;
  font-size: clamp(12px, 3.2vw, 15px);
}

.pill-low {
  background-color: #51d87085;
}

.pill-medium {
  background-color: #51d870b3;
}

.pill-high {
  background-color: #51d870;
}

.pill-unknown {
  background-color: #6c757d; /* Grijs voor onbekende impact */
}

.pill-high-quick {
  background-color: #51d870f2;
  padding: 10px 30px;
}

.pill-high-short {
  background-color: #51d87099;
  padding: 10px 30px;
}

.pill-high-medium {
  background-color: #51d87050;
  padding: 10px 30px;
}

.pill-medium-short {
  background-color: #fbe7a3;
  padding: 10px 30px;
}

.pill-medium-long {
  background-color: #fdf3d0;
  padding: 10px 30px;
}

.pill-low-long {
  background-color: #f8e5d8;
  padding: 10px 30px;
}

.indicator-status-description {
  font-size: clamp(12px, 3.5vw, 15px);
}

.indicator-description {
  color: var(--color-primary);
  font-size: clamp(12px, 3.5vw, 15px);
}

.indicator-description-subtitle {
  font-weight: 700;
  margin: 0px;
  color: var(--color-primary);
  font-size: clamp(12px, 3.5vw, 15px);
}

.indicator-reason-subtitle {
  font-weight: 700;
  margin-top: 15px;
  color: var(--color-primary);
  font-size: clamp(12px, 3.5vw, 15px);
}

h2.results {
  margin: 30px 0px 10px;
  font-weight: 700;
  font-size: clamp(12px, 5vw, 30px);
}

.results-select-button {
  font-size: clamp(12px, 3.5vw, 15px);
}

.language-toggle {
  margin-left: 1vw;
}

.dropdown-text {
  font-size: clamp(12px, 3.5vw, 15px);
  text-align: center;
  width: 100%;
}

.rendered-html-text {
  font-size: clamp(12px, 3.5vw, 15px);
}

.implementation-text {
  font-size: clamp(12px, 3.5vw, 15px);
  margin: 0px 0px 15px;
  font-weight: 700;
}

.recommendations-table {
  font-size: clamp(12px, 3.5vw, 15px);
  font-weight: 700;
}

.recommendation-body-subtitle {
  font-weight: 700;
  margin-top: 15px;
  color: var(--color-primary);
  font-size: clamp(12px, 3.5vw, 15px);
}

.recommendation-benefits {
  font-size: clamp(12px, 3.5vw, 15px);
}

.menu-disclaimer {
  margin: 38% 0% 0% 0%;
  width: 100%;
}

.table-text {
  font-weight: 400;
}

.link-underlined {
  text-decoration: underline;
}

.table-image {
  width: auto;
  height: clamp(50px, 5vw, 60px);
  display: inline-block;
  float: left;
}

.document-wrapper {
  padding-top: 5vh;
  padding-left: 5vw;
  padding-right: 5vw;
  background-color: #fff;
}

.documentation-table {
  width: 98%;
}

.button-documentation {
  width: 8vw;
}

.documentation-table-gap {
  margin: 2vh 1% 3vh 1%;
}

.aside-title {
  text-align: center;
  margin-bottom: 2vh;
}

.table-title {
  display: flex;
  height: clamp(50px, 5vw, 60px);
}

.table-title-p {
  margin: auto 2%;
}

.table-title-h1 {
  margin: auto;
}

.wrapper .table-overflow {
  overflow-x: auto; /* Enables horizontal scrolling */
  max-width: 100%; /* Limits the width to the parent container */
}
